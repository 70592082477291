import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

const HeadingWithEmbed = ({ module }) => {
  const [jobData, setJobData] = useState([]);
  const [careerLimit, setCareerLimit] = useState(3);
  const [popData, setPopData] = useState([]);
  const [skills, setSkills] = useState([]);
  const [allJobData, setAllJobData] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [searchData, setSearchData] = useState({
    city: '',
    industry: '',
    duration: '',
    text: '',
  });
  const customStyles = {
    content: {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      top: '0',
      left: '0',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '0',
      border: '0',
      borderRadius: '0',
      height: '100vh',
      width: '100%',
    },
  };
  useEffect(() => {
    const url = `https://wp.theroyalportfolio.com/wp/wp-admin/admin-ajax.php?action=job_data`;
    var form = new FormData();
    form.append('action', 'job_data');
    const requestOptions = {
      method: 'POST',
      data: form,
      body: form,
      credentials: 'same-origin',
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setJobData(() => {
          return response.job;
        });
        setAllJobData(() => {
          return response.job;
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleFilterData = () => {
    let search_filter_data = allJobData;
    if (searchData.text) {
      let callback = (item) =>
        item.title.includes(searchData.text) ||
        item.city.includes(searchData.text) ||
        item.category.includes(searchData.text) ||
        item.description.includes(searchData.text);
      search_filter_data = search_filter_data.filter(callback);
    }
    if (searchData.industry && searchData.industry !== 'all') {
      let callback = (item) => item.category.includes(searchData.industry);
      search_filter_data = search_filter_data.filter(callback);
    }
    if (searchData.city && searchData.city !== 'all') {
      let callback = (item) => item.city.includes(searchData.city);
      search_filter_data = search_filter_data.filter(callback);
    }
    if (searchData.duration && searchData.duration !== 'all') {
      var pastDate = new Date();
      pastDate.setDate(pastDate.getDate() - searchData.duration);
      let callback = (item) => pastDate <= new Date(item.date_created);
      search_filter_data = search_filter_data.filter(callback);
    }
    setJobData(() => {
      return search_filter_data;
    });
    setCareerLimit(3);
  };

  const handleClearData = () => {
    setJobData(() => {
      return allJobData;
    });
    setSearchData({
      city: '',
      industry: '',
      duration: '',
      text: '',
    });
    setCareerLimit(3);
  };

  function openModal(data) {
    document.body.classList.add('modal-job-open');
    setPopData(data);
    setIsOpen(true);
    setSkills(data.skills.split(','));
  }

  function closeModal() {
    document.body.classList.remove('modal-job-open');
    setPopData([]);
    setIsOpen(false);
    setSkills([]);
  }

  const getFormatedDate = (date) => {
    const monthNames = [
      'Jan',
      'Feb',
      'March',
      'April',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const newDate = new Date(date);
    return (
      newDate.getDate() +
      ' ' +
      monthNames[newDate.getMonth()] +
      ' ' +
      newDate.getFullYear()
    );
  };

  const getEmpType = (type) => {
    if (type === 'full_time') {
      return 'Full Time';
    }
    return type;
  };
  return (
    <>
      <section className="discover-careers  lg:px-60 py-30">
        <div className="container-fluid-md">
          <div className="bg-white lg:p-100 desktop2:p-50 p-20 mdscreen:px-20 shadow-card">
            <div className="fade-ani px-30 pb-20 lgscreen:pb-0">
              <div className="title-black text-center">
                <h3>{module.heading}</h3>
              </div>
              {module.subheading && (
                <div className="content text-md text-center my-20 max-w-600 m-auto mt-15">
                  {module.subheading && parse(module.subheading)}
                </div>
              )}
            </div>
            <div className="discover-search">
              <div className="w-[317px] m-auto relative smscreen2:w-full">
                <input
                  type="text"
                  value={searchData.text}
                  onChange={(e) => {
                    setSearchData((prevData) => ({
                      ...prevData,
                      text: e.target.value,
                    }));
                  }}
                  className="w-full p-10 placeholder:text-black-300 uppercase placeholder:text-12 border-[0.6px] border-gray-100 outline-none text-12"
                  placeholder="Search"
                />
                <button
                  onClick={handleFilterData}
                  className="absolute right-10 top-50-per translate-y-minus_50"
                >
                  <img
                    src="/images/search-icon2.png"
                    className="max-w-20"
                    height="17.19"
                    width="20"
                    alt=""
                  />
                </button>
              </div>
            </div>
            <div className="discover-filter py-60 xlscreen:pb-20">
              <form>
                <div className="discover-filter-grid flex justify-between xlscreen:flex-col">
                  <div className="discover-filter-info w-full flex xlscreen:flex-col">
                    <label className="uppercase inline-block text-black-200 text-12 min-w-[90px] pt-5 xlscreen:text-center xlscreen:w-full xlscreen:mb-15">
                      Job Filter
                    </label>
                    <div className="form-group w-full flex justify-between gap-x-10 desktop2:gap-x-5 mdscreen:flex-col mdscreen:gap-y-5">
                      <select
                        value={searchData.city}
                        placeholder="City"
                        onChange={(e) => {
                          setSearchData((prevData) => ({
                            ...prevData,
                            city: e.target.value,
                          }));
                        }}
                      >
                        <option value="all">All Cities</option>
                        {[...new Set(allJobData.map((item) => item.city))].map(
                          (item, i) => {
                            return (
                              <option key={i} value={item}>
                                {' '}
                                {item}
                              </option>
                            );
                          }
                        )}
                      </select>
                      <select
                        value={searchData.industry}
                        onChange={(e) => {
                          setSearchData((prevData) => ({
                            ...prevData,
                            industry: e.target.value,
                          }));
                        }}
                      >
                        <option value="all">All Industries</option>
                        {[
                          ...new Set(allJobData.map((item) => item.category)),
                        ].map((item, i) => {
                          return (
                            <option key={i} value={item}>
                              {parse(item).replace(/,/g, ', ')}
                            </option>
                          );
                        })}
                      </select>
                      <select
                        value={searchData.duration}
                        onChange={(e) => {
                          setSearchData((prevData) => ({
                            ...prevData,
                            duration: e.target.value,
                          }));
                        }}
                      >
                        <option value="all">All Dates</option>
                        <option value={1}>Last 24 hours</option>
                        <option value={7}>Last 7 Days</option>
                        <option value={14}>Last 14 Days</option>
                        <option value={30}>Last 30 Days</option>
                      </select>
                    </div>
                  </div>
                  <div className="discover-filter-button flex justify-between xlscreen:justify-center xlscreen:mt-15">
                    <div className="ml-30 mdscreen:ml-0">
                      <input
                        type="button"
                        onClick={handleClearData}
                        className="button button-transparent cursor-pointer"
                        value="RESET"
                      />
                    </div>
                    <div className="ml-10">
                      <input
                        type="button"
                        onClick={handleFilterData}
                        className="button button-dark cursor-pointer"
                        value="APPLY"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="discover-job lg:mt-50 mt-25">
              {jobData.slice(0, careerLimit).map((item, i) => {
                return (
                  <div className="dj-grid" key={i}>
                    <div className="flex flex-wrap justify-between items-center">
                      <div className="lg:w-2/12 w-full">
                        <img
                          src={item.company_photo}
                          className="max-w-100 m-auto lgscreen:ml-0 mdscreen:mb-20"
                        />
                      </div>
                      <div className="lg:w-8/12 w-full">
                        <h2 className="text-24 leading-26 mdscreen:text-22 mdscreen:leading-25">
                          {item.title}
                        </h2>
                        <span className="text-13 w-full inline-block mt-10">
                          {item.city}
                        </span>
                        <label className="py-[6px] px-15 uppercase text-10 text-[#666366] bg-[#F8F8F8] rounded-[30px] my-15 inline-block">
                          {parse(item.industries)}
                        </label>
                        <div className="content global-list mdscreen:!max-h-[60px]">
                          {parse(item.description)}
                        </div>
                      </div>
                      <div className="lg:w-2/12 w-full text-right pr-15 xlscreen:pr-0 lgscreen:text-left lgscreen:mt-15">
                        <a
                          onClick={() => {
                            openModal(item);
                          }}
                          className="button button-gray cursor-pointer inline-block"
                        >
                          VIEW
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
              {jobData?.length > careerLimit && (
                <div className="btn-custom text-center justify-center cursor-pointer lg:mt-80 mt-40">
                  <a
                    onClick={() => {
                      setCareerLimit((prevLimit) => prevLimit + 3);
                    }}
                    className="button button-transparent bg-[#e2e2e2]"
                  >
                    Load More
                  </a>
                </div>
              )}
            </div>
            {jobData.length === 0 && (
              <div className="text-center">
                <p>No Jobs Found</p>
              </div>
            )}
          </div>
        </div>
      </section>

      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={closeModal}
      >
        <div className="img-modal img-modal-popup !h-auto">
          <div className="flex flex-wrap items-center bg-white shadow-card relative justify-center">
            <button
              className="modal-close absolute right-30 top-30 lgscreen:bg-white z-9 lgscreen:w-50 lgscreen:h-50 inline-block rounded-100 text-center mdscreen:top-0 mdscreen:right-0"
              onClick={closeModal}
            >
              <img
                src="/images/close2.png"
                className="m-auto lgscreen:max-w-20"
                alt="close"
              />
            </button>

            <div className="lg:w-12/12 w-full">
              <div className="pop-content flex flex-col justify-center items-start lg:py-60 py-30 lg:px-100 px-30 text-left">
                <div className="fade-ani px-30 pb-20 lgscreen:pb-0 w-[700px] m-auto lgscreen:w-full">
                  <div className="title-black text-center">
                    <h3>{module.heading}</h3>
                  </div>
                  {module.subheading && (
                    <div className="content text-md text-center my-20 m-auto mt-15">
                      <p>{module.subheading && parse(module.subheading)}</p>
                    </div>
                  )}
                </div>
                <div className="flex flex-wrap gap-x-10 justify-between smscreen2:flex-col smscreen2:text-center w-full lg:mt-30">
                  <div className="job-title">
                    <h6 className="text-16">Job title</h6>
                    <span className="text-11 text-dark">{popData.title}</span>
                  </div>
                  <div className="job-title">
                    <h6 className="text-16">Employment type</h6>
                    <span className="text-11 text-dark">
                      {getEmpType(popData.jobtype)}
                    </span>
                  </div>
                  <div className="job-title">
                    <h6 className="text-16">Experience</h6>
                    <span className="text-11 text-dark">
                      {popData.experience}
                    </span>
                  </div>
                  <div className="job-title">
                    <h6 className="text-16">Date Published</h6>
                    <span className="text-11 text-dark">
                      {getFormatedDate(popData.date_created)}
                    </span>
                  </div>
                </div>
                <div className="text-center w-full mt-40">
                  <a
                    target="_blank"
                    className="button button-dark"
                    href={popData.url}
                  >
                    APPLY for Position
                  </a>
                </div>
                <div className="border-t-1 border-gray-1000 border-opacity-40 lg:mt-60 mt-30">
                  <div className="content global-list lg:pt-60 pt-30 ">
                    <h4>Job Description</h4>
                    {popData.description && parse(popData.description)}
                  </div>
                  <div className="skillcontent flex flex-wrap w-full justify-between lg:py-30 py-15 border-t-1 border-gray-1000 border-opacity-40 lg:mt-50 mt-25">
                    <div className="lg:w-6/12 w-full">
                      <div>
                        <h6 className="lg:text-30 text-20">Skills</h6>
                        {skills.map((skill, i) => (
                          <span
                            key={i}
                            className="border-1 border-gray-100 py-5 px-20 uppercase text-10 text-black-300 my-5 mx-5 inline-block"
                          >
                            {skills[i]}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="w-full text-center">
                    <a
                      target="_blank"
                      className="button button-dark"
                      href={popData.url}
                    >
                      APPLY for Position
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HeadingWithEmbed;

export const HeadingWithEmbedFragment = graphql`
  fragment HeadingWithEmbedFragment on WpPage_Flexiblecontent_FlexibleContent_HeadingWithEmbed {
    # content
    embed
    fieldGroupName
    subheading
    heading
    embed
  }
`;
